export const types = {
  SHOW_MODAL: 'moankpay/ui/SHOW_MODAL',
  HIDE_MODAL: 'moankpay/ui/HIDE_MODAL',
  SELECT_BANK_ID_TYPE: 'moankpay/ui/SELECT_BANK_ID_TYPE',
  FETCH_APPLICATION_REQUEST: 'moankpay/ui/application/FETCH_APPLICATION_REQUEST',
  FETCH_APPLICATION_SUCCESS: 'moankpay/ui/application/FETCH_APPLICATION_SUCCESS',
  FETCH_APPLICATION_FAILURE: 'moankpay/ui/application/FETCH_APPLICATION_FAILURE',
  FETCH_PARTNER_DETAILS_REQUEST: 'moankpay/ui/application/FETCH_PARTNER_DETAILS_REQUEST',
  FETCH_PARTNER_DETAILS_SUCCESS: 'moankpay/ui/application/FETCH_PARTNER_DETAILS_SUCCESS',
  FETCH_PARTNER_DETAILS_FAILURE: 'moankpay/ui/application/FETCH_PARTNER_DETAILS_FAILURE',
  SUBMIT_SIGN_DATA_REQUEST: 'moankpay/ui/application/SUBMIT_SIGN_DATA_REQUEST',
  SUBMIT_SIGN_DATA_SUCCESS: 'moankpay/ui/application/SUBMIT_SIGN_DATA_SUCCESS',
  SUBMIT_SIGN_DATA_FAILURE: 'moankpay/ui/application/SUBMIT_SIGN_DATA_FAILURE',
  RESTORE_UI_REDUCER: 'moankpay/ui/application/RESTORE_UI_REDUCER',
  FETCH_UI_CONSTANTS_REQUEST: 'moankpay/ui/application/FETCH_UI_CONSTANTS_REQUEST',
  FETCH_UI_CONSTANTS_SUCCESS: 'moankpay/ui/application/FETCH_UI_CONSTANTS_SUCCESS',
  FETCH_UI_CONSTANTS_FAILURE: 'moankpay/ui/application/FETCH_UI_CONSTANTS_FAILURE',
};

export const showModal = (modalData) => {
  const modal = { type: modalData.type || modalData };
  if (typeof modalData !== 'string') {
    modal.options = modalData.options;
    modal.props = modalData.props;
  }
  return {
    type: types.SHOW_MODAL,
    modal,
  };
};

export const hideModal = () => ({ type: types.HIDE_MODAL });

export const selectBankIdType = (bankIdType) => ({
  type: types.SELECT_BANK_ID_TYPE,
  payload: { bankIdType },
});

export const fetchApplication = (applicationId) => ({
  type: types.FETCH_APPLICATION_REQUEST,
  applicationId,
});
export const fetchApplicationSuccess = (application) => ({
  type: types.FETCH_APPLICATION_SUCCESS,
  application,
});
export const fetchApplicationFailure = () => ({ type: types.FETCH_APPLICATION_FAILURE });

export const fetchPartnerDetails = (partnerId) => ({
  type: types.FETCH_PARTNER_DETAILS_REQUEST,
  partnerId,
});
export const fetchPartnerDetailsSuccess = (partner) => ({
  type: types.FETCH_PARTNER_DETAILS_SUCCESS,
  partner,
});
export const fetchPartnerDetailsFailure = () => ({ type: types.FETCH_PARTNER_DETAILS_FAILURE });

export const updateBankAccountId = () => ({
  type: types.SUBMIT_SIGN_DATA_REQUEST,
});

export const updateBankAccountIdSuccess = () => ({ type: types.SUBMIT_SIGN_DATA_SUCCESS });

export const updateBankAccountIdFailure = () => ({ type: types.SUBMIT_SIGN_DATA_FAILURE });

export const restoreUiReducer = (uiReducer) => ({ type: types.RESTORE_UI_REDUCER, uiReducer });

export const fetchUiConstants = () => ({ type: types.FETCH_UI_CONSTANTS_REQUEST });

export const fetchUiConstantsFailure = () => ({ type: types.FETCH_UI_CONSTANTS_FAILURE });

export const fetchUiConstantsSuccess = (activeBanks) => ({
  type: types.FETCH_UI_CONSTANTS_SUCCESS, activeBanks,
});
