import React from 'react';
import PropTypes from 'prop-types';

import Input from './Input';

const BankSelect = ({
  label,
  bank,
  banks,
  onChange,
}) => (
  <Input
    _id="bankProvider"
    inputType="select"
    label={label}
    value={bank.value}
    onChange={onChange}
    values={banks}
    hasError={bank.valid === false}
    isSuccess={bank.valid}
    defaultOption="Välj alternativ"
  />
);

BankSelect.propTypes = {
  banks: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
  bank: PropTypes.shape({
    value: PropTypes.string.isRequired,
    valid: PropTypes.bool,
  }),
  label: PropTypes.string,
  onChange: PropTypes.func.isRequired,
};

BankSelect.defaultProps = {
  label: 'Välj din bank',
  bank: {
    value: '',
    valid: false,
  },
};

export default BankSelect;
