import responseHandler from '../utils/responseHandler';
import requestBuilder from '../utils/requestBuilder';
import requestsMethods from '../constants/requestsMethods';
import api from '../constants/api';

const retrieveApplicationData = responseHandler(
  async (applicationId) => requestBuilder({
    method: requestsMethods.GET,
    url: api.APPLICATION_RETRIEVE_DATA(applicationId),
  }),
);

export default retrieveApplicationData;
