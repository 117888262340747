import styled from 'styled-components';
import { rgba } from 'polished';
import StyledInput from './Input/StyledInput';

import style from '../../constants/style';

export const StyledSelect = styled(StyledInput)`
  width: 100%;
  height: 3rem;
  padding-right: 2.5rem;
  position: relative;
  background-repeat: no-repeat;
  background-size: 1.5rem 1rem;
  background-position: right 0.5rem center;
  background-clip: border-box;
  background-color: ${style.colours.secondaryLightGray2};
  border: 2px solid ${style.colours.secondaryDarkGray4};

  color: ${(props) => (props.value ? style.colours.secondaryDarkGray1 : rgba(style.colours.secondaryDarkGray1, 0.5))};
`;

const Select = StyledSelect;

export default Select;
