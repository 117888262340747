import React, { useEffect } from 'react';
import { useDispatch } from 'react-redux';

import { handleBankLoginRedirect } from '../redux/bank/actions';
import Page from './containers/Page';
import Loader from './elements/Loader';

const BankLoginCallback = () => {
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(handleBankLoginRedirect());
  }, [dispatch]);

  return (
    <Page>
      <Loader message="Hämtar information..." />
    </Page>
  );
};

export default BankLoginCallback;
