import { useEffect } from 'react';

const useModalFocus = (closeButtonRef) => {
  useEffect(() => {
    const openingElement = global.document.activeElement;
    if (closeButtonRef.current) closeButtonRef.current.focus();
    return () => {
      if (openingElement) openingElement.focus();
    };
  }, [closeButtonRef]);
};

export default useModalFocus;
