import React from 'react';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';

import StyledButton from './StyledButton';

const SafelyWrappedLink = ({
  size,
  type,
  softDisabled,
  noTextTransform,
  ...rest
// eslint-disable-next-line react/jsx-props-no-spreading
}) => (<Link {...rest} />);

SafelyWrappedLink.propTypes = {
  disabled: PropTypes.bool,
  size: PropTypes.string,
  type: PropTypes.string,
  softDisabled: PropTypes.bool,
  noTextTransform: PropTypes.bool,
};

SafelyWrappedLink.defaultProps = {
  disabled: false,
  size: 'regular',
  type: 'primary',
  softDisabled: false,
  noTextTransform: false,
};

const Button = React.forwardRef(({
  children,
  disabled,
  onClick,
  to,
  href,
  onMouseEnter,
  onMouseLeave,
  target,
  size,
  type,
  hovertype,
  softDisabled,
  className,
  rel,
  noTextTransform,
}, ref) => {
  if (onClick) {
    return (
      <StyledButton
        onClick={onClick}
        disabled={disabled || softDisabled}
        softDisabled={softDisabled}
        onMouseEnter={onMouseEnter}
        onMouseLeave={onMouseLeave}
        size={size}
        type={type}
        hovertype={hovertype}
        ref={ref}
        className={className}
        noTextTransform={noTextTransform}
      >
        {children}
      </StyledButton>
    );
  }
  if (to) {
    return (
      <StyledButton
        as={SafelyWrappedLink}
        to={to}
        onClick={(e) => disabled && e.preventDefault()}
        disabled={disabled || softDisabled}
        softDisabled={softDisabled}
        onMouseEnter={onMouseEnter}
        onMouseLeave={onMouseLeave}
        size={size}
        type={type}
        hovertype={hovertype}
        ref={ref}
        className={className}
        rel={rel}
        noTextTransform={noTextTransform}
      >
        {children}
      </StyledButton>
    );
  }
  if (href) {
    return (
      <StyledButton
        as="a"
        href={href}
        disabled={disabled || softDisabled}
        softDisabled={softDisabled}
        onMouseEnter={onMouseEnter}
        onMouseLeave={onMouseLeave}
        target={target}
        size={size}
        type={type}
        hovertype={hovertype}
        ref={ref}
        className={className}
        rel={rel}
        noTextTransform={noTextTransform}
      >
        {children}
      </StyledButton>
    );
  }
  throw new Error('A Button cannot be without "onClick", "href" and "to" prop');
});

Button.propTypes = {
  children: PropTypes.node.isRequired,
  onClick: PropTypes.func,
  onMouseEnter: PropTypes.func,
  onMouseLeave: PropTypes.func,
  to: PropTypes.string,
  href: PropTypes.string,
  disabled: PropTypes.bool,
  softDisabled: PropTypes.bool,
  target: PropTypes.string,
  size: PropTypes.string,
  type: PropTypes.string,
  hovertype: PropTypes.string,
  className: PropTypes.string,
  rel: PropTypes.string,
  noTextTransform: PropTypes.bool,
};

Button.defaultProps = {
  disabled: false,
  softDisabled: false,
  onClick: null,
  onMouseEnter: null,
  onMouseLeave: null,
  to: null,
  href: null,
  target: undefined,
  size: 'regular',
  type: 'primary',
  hovertype: 'hover',
  className: undefined,
  rel: undefined,
  noTextTransform: false,
};

export default Button;
