import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';

import style from '../../constants/style';

import ModalHost from '../modules/modals/common';
import Header, { HEADER_HEIGHT } from '../elements/Header';
import Footer from '../elements/Footer';
import GlobalStyle from '../GlobalStyles';

const StyledPage = styled.div`
  display: flex;
  flex-direction: column;
  position: relative;
  flex-grow: 1;
  z-index: ${style.zLevels.normal};
  color: ${style.colours.primaryDark};
  background-color: ${({ theme }) => theme.pageBackground || theme.background};
  font-size: ${style.fontSize.regular};
  align-items: center;
  min-height: 100vh;
`;

const StyledPageContent = styled('div')`
  display: flex;
  flex-direction: column;

  margin-top: ${HEADER_HEIGHT};
  padding: ${style.padding.medium};

  max-width: 40rem;
`;

const Page = ({ children }) => (
  <StyledPage>
    <GlobalStyle />
    <ModalHost />
    <Header />
    <StyledPageContent>
      {children}
    </StyledPageContent>
    <Footer />
  </StyledPage>
);

Page.propTypes = {
  children: PropTypes.node.isRequired,
};

export default Page;
