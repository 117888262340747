import responseHandler from '../utils/responseHandler';
import requestBuilder from '../utils/requestBuilder';
import requestsMethods from '../constants/requestsMethods';
import api from '../constants/api';

const updateBankAccountId = responseHandler(
  async (applicationId, signerId, bankAccountId, termsApproved, canBeProfiled) => requestBuilder({
    method: requestsMethods.PUT,
    url: api.SUBMIT_SIGN_DATA(applicationId, signerId),
    data: {
      bankAccountId,
      termsApproved,
      canBeProfiled,
    },
  }),
);

export default updateBankAccountId;
