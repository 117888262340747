import { types } from './actions';
import { types as bankTypes } from '../bank/actions';

export const initialState = {
  bankIdType: '',
  bankIdLoading: false,
  modal: {
    error: false,
  },
  application: {
    socialSecurityNumber: '',
    id: '',
    loading: false,
    applicationStep: '',
  },
  partner: {
    logoKey: '',
  },
  constants: {
    banks: [],
  },
};

export default (state = initialState, action) => {
  switch (action.type) {
    case types.SHOW_MODAL: {
      return {
        ...state,
        modal: {
          ...state.modal,
          ...action.modal,
        },
      };
    }
    case types.HIDE_MODAL:
      return {
        ...state,
        modal: initialState.modal,
      };
    case types.SELECT_BANK_ID_TYPE: {
      return {
        ...state,
        bankIdType: action.payload.bankIdType,
      };
    }
    case bankTypes.SIGN_APPLICATION: {
      return {
        ...state,
        bankIdLoading: true,
      };
    }
    case bankTypes.SIGN_APPLICATION_SUCCESS:
    case bankTypes.SIGN_APPLICATION_FAILURE: {
      return {
        ...state,
        bankIdLoading: false,
      };
    }
    case types.FETCH_APPLICATION_REQUEST: {
      return {
        ...state,
        application: {
          loading: true,
        },
      };
    }
    case types.FETCH_APPLICATION_SUCCESS: {
      return {
        ...state,
        application: {
          socialSecurityNumber: action.application.applicants[0].socialSecurityNumber,
          id: action.application.id,
          applicants: action.application.applicants,
          loading: false,
          applicationStep: action.application.applicationStep,
          services: action.application.externalData.services,
        },
      };
    }
    case types.FETCH_APPLICATION_FAILURE: {
      return {
        ...state,
        application: {
          loading: false,
        },
      };
    }
    case types.FETCH_PARTNER_DETAILS_REQUEST:
    case types.FETCH_PARTNER_DETAILS_FAILURE: {
      return {
        ...state,
      };
    }
    case types.FETCH_PARTNER_DETAILS_SUCCESS: {
      return {
        ...state,
        partner: {
          logoKey: action.partner.logoKey,
        },
      };
    }
    case types.SUBMIT_SIGN_DATA_REQUEST: {
      return {
        ...state,
        application: {
          ...state.application,
          loading: true,
        },
      };
    }
    case types.SUBMIT_SIGN_DATA_SUCCESS:
    case types.SUBMIT_SIGN_DATA_FAILURE: {
      return {
        ...state,
        application: {
          ...state.application,
          loading: false,
        },
      };
    }
    case types.RESTORE_UI_REDUCER: {
      return {
        ...action.uiReducer,
      };
    }
    case types.FETCH_UI_CONSTANTS_REQUEST:
    case types.FETCH_UI_CONSTANTS_FAILURE: {
      return {
        ...state,
      };
    }
    case types.FETCH_UI_CONSTANTS_SUCCESS: {
      return {
        ...state,
        constants: {
          banks: action.activeBanks,
        },
      };
    }
    default: {
      return state;
    }
  }
};
