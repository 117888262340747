import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';

import style from '../../../../constants/style';

import CloseButton from './ModalCloseButton';

const modalMargins = {
  mobile: {
    vertical: '1rem',
    horizontal: '1rem',
  },
  laptop: {
    vertical: '4rem',
    horizontal: 'auto',
  },
};

const getTopPadding = (
  { closeButtonHidden },
) => (closeButtonHidden ? '0rem' : '3rem');

const StyledModal = styled.div`
  position: relative;
  width: calc(100% - 2 * ${modalMargins.mobile.horizontal});
  margin: ${modalMargins.mobile.vertical} ${modalMargins.mobile.horizontal};
  padding-top: ${(props) => getTopPadding(props)};
  background-color: ${style.colours.secondaryLightGray1};
  box-shadow: ${style.shadows};
  max-height: calc(100% - 2 * ${modalMargins.mobile.vertical} - ${(props) => getTopPadding(props)});
  display: flex;
  align-items: stretch;
  flex-direction: column;

  ${style.media.laptop`
    max-width: ${({ large }) => (large ? '70rem' : '40rem')};
    margin: ${modalMargins.laptop.vertical} ${modalMargins.laptop.horizontal};
    padding-top: ${(props) => getTopPadding(props)};
    ${({ large }) => (large ? 'min-height: 20rem;' : '')};
    max-height: calc(100% - 2 * ${modalMargins.laptop.vertical} - ${(props) => getTopPadding(props)});
  `}
`;

export const ModalCloseButton = React.forwardRef(({
  onClick,
}, ref) => (
  <CloseButton onClick={onClick} ref={ref} />
));

ModalCloseButton.propTypes = {
  onClick: PropTypes.func.isRequired,
};

const Modal = ({ large, closeButtonHidden, children }) => (
  <StyledModal large={large} closeButtonHidden={closeButtonHidden}>{children}</StyledModal>
);

Modal.propTypes = {
  large: PropTypes.bool,
  closeButtonHidden: PropTypes.bool,
  children: PropTypes.node.isRequired,
};

Modal.defaultProps = {
  large: false,
  closeButtonHidden: false,
};

export default Modal;
