import { all } from 'redux-saga/effects';

import uiReducer from './ui/reducer';
import uiSaga from './ui/saga';

import bankReducer from './bank/reducer';
import bankSaga from './bank/saga';

export const rootReducer = {
  bank: bankReducer,
  ui: uiReducer,
};

export function* rootSaga() {
  yield all([
    bankSaga(),
    uiSaga(),
  ]);
}
