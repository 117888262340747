import { DateTime } from 'luxon';

const luhnCheck = (value) => {
  const parity = value.length % 2;
  let checkDigit = 0;
  for (let index = value.length - 1; index >= 0; index -= 1) {
    let digit = parseInt(value[index], 10);
    if (index % 2 === parity) { digit *= 2; }
    if (digit > 9) { digit -= 9; }
    checkDigit += digit;
  }
  return checkDigit % 10 === 0;
};

const isDefined = (value) => value !== undefined && value !== null && value !== '';

const socialSecurityNumber = (value) => {
  if (typeof value !== 'string') return false;
  if (!(/^(20|19)[0-9]{10}$/.test(value))) return false;
  if (!DateTime.fromFormat(value.slice(0, 8), 'yyyyMMdd').isValid) return false;
  return luhnCheck(value.slice(2));
};

export default {
  socialSecurityNumber,
  bankAccountId: (value) => isDefined(value),
  termsApproved: (value) => value === true,
  canBeProfiled: (value) => value === true,
  bankProvider: (value) => isDefined(value),
};
