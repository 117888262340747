const routes = {
  _baseUrl: '/',
  LANDING_PAGE: { _baseUrl: '/betalning/:applicationId' },
  BANK_SELECT: { _baseUrl: '/betalning/:applicationId/bank-select' },
  BANK_LOGIN_CALLBACK: { _baseUrl: '/bank-login-callback' },
  BANK_ACCOUNT_SELECTION: { _baseUrl: '/betalning/:applicationId/bankkonto' },
  THANK_YOU_PAGE: { _baseUrl: '/betalning/:applicationId/tack' },
};

export default routes;
