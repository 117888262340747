import React from 'react';
import styled from 'styled-components';
import Paragraph, { StyledParagraph } from './Paragraph';
import style from '../../constants/style';

const currentYear = () => new Date().getFullYear();

const FOOTER_PADDING = style.padding.regular;

const FooterStyled = styled('footer')`
  background-color: ${style.colours.primaryDark};
  padding: ${FOOTER_PADDING};
  width: calc(100% - ${FOOTER_PADDING} * 2);
  display: flex;
  justify-content: center;
  margin-top: auto;

  ${StyledParagraph} {
    color: ${style.colours.secondaryLightGray1};
    max-width: 20rem;
    text-align: center;
    font-size: ${style.fontSize.smaller};
  }
`;

const Footer = () => (
  <FooterStyled>
    <Paragraph>
      Copyright ©{currentYear()} Moank. Alla rättigheter förbehålls.
      Moank&nbsp;AB är ett kreditmarknadsbolag med tillstånd av Finansinspektionen.
      Org.nr: 559000-0237. Styrelsens säte: Stockholm.
    </Paragraph>
  </FooterStyled>
);

export default Footer;
