import styled from 'styled-components';
import style from '../../constants/style';

import Icon from './Icon';

export const StyledLogo = styled(Icon)`
  height: ${({ height }) => height || '2rem'};
  max-width: 100%;

  ${style.media.laptop`
    height: ${({ height }) => height || '2.25rem'};
  `}
`;

const Logo = StyledLogo;

export default Logo;
