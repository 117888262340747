import React from 'react';

import styled from 'styled-components';
import style from '../../constants/style';

import Logo from './Logo';
import MoankPayLogo from '../../assets/moankpay.svg';

export const HEADER_HEIGHT = '4.5rem';

const HeaderStyled = styled.header`
  display: flex;
  position: fixed;
  top: 0;
  justify-content: space-between;
  align-items: center;

  padding: ${style.padding.regular} ${style.padding.regular};
  height: calc(${HEADER_HEIGHT} - 2 * ${style.padding.regular});
  width: calc(100% - 2 * ${style.padding.regular});
  background-color: ${style.colours.secondaryLightGray1};
  box-shadow: ${style.shadows};

  z-index: ${style.zLevels.header};
`;

const Header = () => (
  <HeaderStyled>
    <Logo alt="" icon={MoankPayLogo} height="1.25rem" />
  </HeaderStyled>
);

export default Header;
