import styled from 'styled-components';
import style from '../../constants/style';

export const StyledHeadline = styled.h1`
  font-size: ${({ fontSize }) => style.headlineFontSizes.mobile[fontSize]};
  font-weight: ${({ fontWeight }) => style.fontWeight[fontWeight]};
  font-stretch: normal;
  text-align: ${({ textAlign }) => textAlign};
  text-transform: ${({ textTransform }) => textTransform};
  font-feature-settings: 'liga' off, 'calt' off;
  ${({ color }) => (color ? `color: ${style.colours[color]};` : '')}
  hyphens: manual;

  ${({ tabletBig }) => tabletBig && style.media.tablet`
    font-size: ${({ fontSize }) => style.headlineFontSizes.laptop[fontSize]};
  `};

  ${style.media.laptop`
    font-size: ${({ fontSize }) => style.headlineFontSizes.laptop[fontSize]};
  `}

  margin-top: ${(props) => style.margin[props.margin] || style.margin[props.marginTop]};
  margin-bottom: ${(props) => style.margin[props.margin] || style.margin[props.marginBottom]};
  margin-left: ${(props) => style.margin[props.margin] || style.margin[props.marginLeft]};
  margin-right: ${(props) => style.margin[props.margin] || style.margin[props.marginRight]};

  ${style.collapseMarginMixin()}
`;

StyledHeadline.defaultProps = {
  fontSize: 'normal',
  textAlign: 'left',
  textTransform: 'none',
  fontWeight: 'regular',
  color: undefined,
  margin: undefined,
  marginTop: 'regular',
  marginBottom: 'big',
  marginLeft: 'none',
  marginRight: 'none',
  tabletBig: false,
};

const Headline = StyledHeadline;

export default Headline;
