const screenTypes = {
  MOBILE: 'mobile',
  TABLET: 'tablet',
  LAPTOP: 'laptop',
  DESKTOP: 'desktop',
};

export const orderedScreenTypes = [
  screenTypes.MOBILE,
  screenTypes.TABLET,
  screenTypes.LAPTOP,
  screenTypes.DESKTOP,
];

export default screenTypes;
