import responseHandler from '../utils/responseHandler';
import requestBuilder from '../utils/requestBuilder';
import api from '../constants/api';
import requestsMethods from '../constants/requestsMethods';

export const startBankLoginSession = responseHandler(async (
  provider,
  parameters,
) => requestBuilder({
  method: requestsMethods.POST,
  url: api.BANK_START_LOGIN_SESSION,
  data: { provider, parameters },
}));

export const fetchBankLoginSession = responseHandler(async (
  sessionId,
) => requestBuilder({
  method: requestsMethods.POST,
  url: api.BANK_CHECK_LOGIN_SESSION(sessionId),
}));

export const retrieveBankData = responseHandler(async (data) => requestBuilder({
  method: requestsMethods.POST,
  url: api.BANK_RETRIEVE_DATA,
  data,
}));

export const fetchBankAuthenticationLink = responseHandler(async (data) => requestBuilder({
  method: requestsMethods.POST,
  url: api.BANK_AUTHENTICATION_LINK,
  data,
}));

export const getSignApplicationInfo = responseHandler(
  async (applicationId, signerId, data) => requestBuilder({
    method: requestsMethods.POST,
    url: api.GET_SIGN_APPLICATION_INFO(applicationId, signerId),
    data,
  }),
);

export const signApplication = responseHandler(
  async (applicationId, signerId, data, orderRef) => requestBuilder({
    method: requestsMethods.POST,
    url: api.SIGN_APPLICATION(applicationId, signerId),
    data,
    queryParams: { orderRef },
  }),
);
