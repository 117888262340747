import {
  all,
  put,
  takeEvery,
  select,
  call,
} from 'redux-saga/effects';
import {
  types,
  showModal,
  fetchApplicationFailure,
  fetchApplicationSuccess,
  fetchPartnerDetails,
  fetchPartnerDetailsFailure,
  fetchPartnerDetailsSuccess,
  updateBankAccountIdSuccess,
  updateBankAccountIdFailure,
  fetchUiConstantsFailure,
  fetchUiConstantsSuccess,
} from './actions';
import retrieveApplicationData from '../../apiClient/application';
import errorLogger from '../../utils/errorLogger';
import retrievePartnerDetails from '../../apiClient/partner';
import updateBankAccountId from '../../apiClient/updateBankAccountId';

import modalTypes from '../../components/modules/modals/common/types';
import settingsFetch from '../../apiClient/settings';

function* fetchApplicationSaga({ applicationId }) {
  try {
    const application = yield call(retrieveApplicationData, applicationId);
    const { partnerId } = application;
    yield put(fetchApplicationSuccess(application));
    yield put(fetchPartnerDetails(partnerId));
  } catch (e) {
    yield put(fetchApplicationFailure());
    yield put(fetchPartnerDetailsFailure());
    if (!e.status || e.status >= 500) {
      yield put(showModal({ type: modalTypes.ERROR }));
      errorLogger(e);
    } else {
      yield put(showModal({
        type: modalTypes.ERROR,
        props: {
          title: 'Felaktig länk',
          message: 'Denna länk verkar redan ha använts eller upphört att gälla. Var vänlig och kontakta kundtjänst om du behöver få en ny länk skickad till dig.',
        },
      }));
    }
  }
}

function* fetchPartnerDetailsSaga({ partnerId }) {
  try {
    const partner = yield call(retrievePartnerDetails, partnerId);
    yield put(fetchPartnerDetailsSuccess(partner));
  } catch (e) {
    yield put(fetchPartnerDetailsFailure());
    errorLogger(e);
  }
}

function* updateBankAccountIdSaga() {
  try {
    const termsApproved = yield select((state) => state.bank.termsApproved.value);
    const canBeProfiled = yield select((state) => state.bank.canBeProfiled.value);
    const applicantId = yield select((state) => state.ui.application.applicants[0].id);
    const applicationId = yield select((state) => state.ui.application.id);
    const bankAccountId = yield select((state) => state.bank.bankAccountId.value);
    yield call(
      updateBankAccountId,
      applicationId,
      applicantId,
      bankAccountId,
      termsApproved,
      canBeProfiled,
    );
    yield put(updateBankAccountIdSuccess());
    yield put(showModal({ type: modalTypes.BANK_ID }));
  } catch (e) {
    yield put(updateBankAccountIdFailure());
    yield put(showModal({ type: modalTypes.ERROR }));
    errorLogger(e);
  }
}

function* fetchUiConstantsSaga() {
  try {
    const settings = yield call(settingsFetch);
    yield put(fetchUiConstantsSuccess(settings.choices.activeBanks));
  } catch (e) {
    yield put(fetchUiConstantsFailure());
    errorLogger(e);
  }
}

export default function* uiSaga() {
  yield all([
    takeEvery(types.FETCH_APPLICATION_REQUEST, fetchApplicationSaga),
    takeEvery(types.FETCH_PARTNER_DETAILS_REQUEST, fetchPartnerDetailsSaga),
    takeEvery(types.SUBMIT_SIGN_DATA_REQUEST, updateBankAccountIdSaga),
    takeEvery(types.FETCH_UI_CONSTANTS_REQUEST, fetchUiConstantsSaga),
  ]);
}
