export const types = {
  START_BANK_LOGIN_SESSION: 'moankpay/bank/START_BANK_LOGIN_SESSION',
  START_BANK_LOGIN_SESSION_SUCCESS: 'moankpay/bank/START_BANK_LOGIN_SESSION_SUCCESS',
  START_BANK_LOGIN_SESSION_FAILURE: 'moankpay/bank/START_BANK_LOGIN_SESSION_FAILURE',
  UPDATE_BANK_LOGIN_SESSION: 'moank/bank/UPDATE_BANK_LOGIN_SESSION',
  RETRIEVE_BANK_DATA: 'moankpay/bank/RETRIEVE_BANK_DATA',
  RETRIEVE_BANK_DATA_SUCCESS: 'moankpay/bank/RETRIEVE_BANK_DATA_SUCCESS',
  RETRIEVE_BANK_DATA_FAILURE: 'moankpay/bank/RETRIEVE_BANK_DATA_FAILURE',
  BANK_RESET: 'moankpay/bank/BANK_RESET',
  HANDLE_BANK_LOGGIN_REDIRECT: 'moankpay/bank/HANDLE_BANK_LOGGIN_REDIRECT',
  UPDATE_FIELD: 'moankpay/bank/UPDATE_FIELD',
  VALIDATE_FIELD: 'moankpay/bank/VALIDATE_FIELD',
  SIGN_APPLICATION: 'moankpay/bank/SIGN_APPLICATION',
  SIGN_APPLICATION_SUCCESS: 'moankpay/bank/SIGN_APPLICATION_SUCCESS',
  SIGN_APPLICATION_FAILURE: 'moankpay/bank/SIGN_APPLICATION_FAILURE',
};

export const startBankLoginSession = (provider, parameters) => ({
  type: types.START_BANK_LOGIN_SESSION,
  provider,
  parameters,
});

export const startBankLoginSessionSuccess = ({ sessionId, scaApproach, link }) => ({
  type: types.START_BANK_LOGIN_SESSION_SUCCESS,
  sessionId,
  scaApproach,
  link,
});

export const startBankLoginSessionFailure = () => ({
  type: types.START_BANK_LOGIN_SESSION_FAILURE,
});

export const updateBankLoginSession = (status, message = '', token = '', image = '') => ({
  type: types.UPDATE_BANK_LOGIN_SESSION,
  status,
  message,
  token,
  image,
});

export const retrieveBankData = (provider, token, sessionId) => ({
  type: types.RETRIEVE_BANK_DATA,
  provider,
  token,
  sessionId,
});

export const retrieveBankDataSuccess = (accounts, reportId) => ({
  type: types.RETRIEVE_BANK_DATA_SUCCESS,
  accounts,
  reportId,
});

export const retrieveBankDataFailure = () => ({
  type: types.RETRIEVE_BANK_DATA_FAILURE,
});

export const bankReset = (message) => ({ type: types.BANK_RESET, message });

export const handleBankLoginRedirect = (sessionId, code, message, error) => ({
  type: types.HANDLE_BANK_LOGGIN_REDIRECT,
  sessionId,
  code,
  message,
  error,
});

export const updateField = (field, value, { min = 0, max = 0 } = {}) => ({
  type: types.UPDATE_FIELD,
  field,
  value,
  min,
  max,
});

export const validateField = (field, valid) => ({ type: types.VALIDATE_FIELD, field, valid });

export const signApplication = () => ({
  type: types.SIGN_APPLICATION,
});

export const signApplicationFailure = () => ({ type: types.SIGN_APPLICATION_FAILURE });
export const signApplicationSuccess = () => ({ type: types.SIGN_APPLICATION_SUCCESS });
