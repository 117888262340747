import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import style from '../constants/style';

import FlexBox from './containers/FlexBox';
import Headline from './elements/Headline';
import Page from './containers/Page';
import Paragraph from './elements/Paragraph';
import PartnerLogo from './PartnerLogo';
import PhoneNumber from './elements/PhoneNumber';

import { bankReset } from '../redux/bank/actions';
import numberFormat from '../utils/numberFormat';
import useFetchApplication from '../utils/useFetchApplication';

import moankInfo from '../constants/moankpayInfo';

const getAmount = (services) => services.reduce((price, service) => price + service.price, 0);

const ThankYouPage = () => {
  const dispatch = useDispatch();
  const services = useSelector((state) => state.ui.application.services);

  useEffect(() => {
    dispatch(bankReset());
  }, [dispatch]);

  useFetchApplication();

  return (
    <Page>
      <PartnerLogo />
      <Headline as="h3" fontSize="small" textAlign="center">Allting är klart</Headline>
      <FlexBox marginBottom="big">
        <Paragraph textAlign="center">
          Du har signerat din försäkring och aktiverat månadsdebitering via autogirot.
          Betalning för första månadens försäkringspremie görs i butik,
          därefter kommer premien dras automatiskt från ditt bankkonto. Autogirot är kostnadsfritt.
        </Paragraph>
      </FlexBox>
      <FlexBox
        flexDirection="column"
        marginBottom="medium"
        justifyContent="center"
      >
        <Paragraph fontWeight="bold">Detaljer</Paragraph>
        <FlexBox
          marginBottom="small"
          paddingBottom="small"
          borderBottomStyle="solid"
          borderBottomWidth="thin"
          borderBottomColor={style.colours.secondaryLightGray5}
          justifyContent="space-between"
        >
          <Paragraph margin="none">Betalsätt</Paragraph>
          <Paragraph fontWeight="bold" margin="none">Månadsbetalning</Paragraph>
        </FlexBox>
        {services && (
          <FlexBox
            marginBottom="small"
            paddingBottom="small"
            borderBottomStyle="solid"
            borderBottomWidth="thin"
            borderBottomColor={style.colours.secondaryLightGray5}
            justifyContent="space-between"
          >
            <Paragraph margin="none">Pris per månad</Paragraph>
            <Paragraph fontWeight="bold" margin="none">{numberFormat(getAmount(services), 2, { unit: 'kr/mån' })}
            </Paragraph>
          </FlexBox>
        )}
      </FlexBox>
      <FlexBox marginBottom="small" flexDirection="column">
        <Paragraph textAlign="center">
          När försäkringen är aktiverad kommer ett välkomstmejl
          från Mediamarkt till din angivna e-postadress.
        </Paragraph>
        <Paragraph textAlign="center" margin="none">
          <PhoneNumber phoneNumber={moankInfo.MM_NUMBER} />
        </Paragraph>
      </FlexBox>
    </Page>
  );
};

export default ThankYouPage;
