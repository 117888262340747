import React, { useEffect, useCallback } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import BackButton from './elements/BackButton';
import BankSelect from './elements/Input/BankSelect';
import FlexBox from './containers/FlexBox';
import InfoButton from './elements/Button/InfoButton';
import Li from './elements/Li';
import List from './elements/List';
import Page from './containers/Page';
import Paragraph from './elements/Paragraph';
import PartnerLogo from './PartnerLogo';
import Stepper from './elements/Stepper';
import TinkLink from './modules/TinkLink';

import routes from '../constants/routes';
import bankLoginActions from '../constants/bankLoginActions';
import paymentSteps from '../constants/paymentSteps';
import { fetchUiConstants } from '../redux/ui/actions';
import { updateField } from '../redux/bank/actions';
import getUrl from '../utils/getUrl';
import useFetchApplication from '../utils/useFetchApplication';

const BankSelectPage = () => {
  const dispatch = useDispatch();

  const applicationId = useSelector((state) => state.ui.application.id);
  const banks = useSelector((state) => state.ui.constants.banks);
  useEffect(() => {
    dispatch(fetchUiConstants());
  }, [dispatch]);

  useFetchApplication();
  const bank = useSelector((state) => state.bank.bankProvider);
  const isValid = useSelector((state) => state.bank.bankProvider.valid
    && state.bank.canBeProfiled.valid
    && state.bank.termsApproved.valid);

  const onChange = useCallback((field, value) => {
    dispatch(updateField(field, value));
  }, [dispatch]);

  return (
    <Page>
      <Stepper step={paymentSteps.BANK_SELECT} />
      <PartnerLogo />
      <BankSelect
        banks={banks}
        onChange={onChange}
        bank={bank}
      />
      <TinkLink
        action={bankLoginActions.UPDATE_BANK_PROVIDER}
        enabled={isValid}
        bank={bank.value}
      />
      <FlexBox flexDirection="column">
        <Paragraph fontSize="regular" marginTop="none">
          Vi samlar in nedanstående finansiell data för att ge dig bästa möjliga villkor:
        </Paragraph>
        <List>
          <Li>Bankkontouppgifter</Li>
        </List>
        <FlexBox marginTop="regular">
          <Paragraph marginBottom="none" marginRight="small">Läs mer om vår datainsamling här.</Paragraph>
          <InfoButton />
        </FlexBox>
      </FlexBox>
      <BackButton previous={getUrl(routes.LANDING_PAGE, { applicationId })} />
    </Page>
  );
};

export default BankSelectPage;
