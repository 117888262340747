import styled from 'styled-components';
import PropTypes from 'prop-types';
import style from '../../constants/style';

export const StyledParagraph = styled.p`
  margin-top: ${(props) => style.margin[props.margin] || style.margin[props.marginTop]};
  margin-bottom: ${(props) => style.margin[props.margin] || style.margin[props.marginBottom]};
  margin-left: ${(props) => style.margin[props.margin] || style.margin[props.marginLeft]};
  margin-right: ${(props) => style.margin[props.margin] || style.margin[props.marginRight]};
  padding-top: ${(props) => style.padding[props.padding] || style.padding[props.paddingTop]};
  padding-bottom: ${(props) => style.padding[props.padding] || style.padding[props.paddingBottom]};
  padding-left: ${(props) => style.padding[props.padding] || style.padding[props.paddingLeft]};
  padding-right: ${(props) => style.padding[props.padding] || style.padding[props.paddingRight]};
  text-align: ${(props) => props.textAlign};
  color: ${({ colour }) => (colour ? style.colours[colour] : 'inherit')};
  font-weight: ${({ fontWeight }) => (fontWeight ? style.fontWeight[fontWeight] : style.fontWeight.regular)};
  font-size: ${({ fontSize }) => style.fontSize[fontSize]};
  text-transform: ${({ textTransform }) => textTransform};
  line-height: 1.5;
  ${(props) => (props.hideSmallScreen ? 'display: none;' : '')}
  ${(props) => (props.italic ? 'font-style: italic;' : '')}

  ${style.collapseMarginMixin()}

  ${(props) => style.media[props.breakSize]`
    ${props.hideLargeScreen ? 'display: none;' : ''}
    ${props.hideSmallScreen ? 'display: block;' : ''}
    ${props.textAlignLargeScreen ? `text-align: ${props.textAlignLargeScreen};` : ''}
    ${props.fontSizeLargeScreen ? `font-size: ${style.fontSize[props.fontSizeLargeScreen]}` : ''};
  `}
`;

StyledParagraph.propTypes = {
  margin: PropTypes.string,
  padding: PropTypes.string,
  textTransform: PropTypes.string,
  textAlign: PropTypes.string,
  textAlignLargeScreen: PropTypes.string,
  hideLargeScreen: PropTypes.bool,
  hideSmallScreen: PropTypes.bool,
  breakSize: PropTypes.oneOf(Object.keys(style.media)),
  colour: PropTypes.oneOf(Object.keys(style.colours)),
  fontSize: PropTypes.oneOf(Object.keys(style.fontSize)),
  fontSizeLargeScreen: PropTypes.oneOf(Object.keys(style.fontSize)),
  fontWeight: PropTypes.oneOf(Object.keys(style.fontWeight)),
  italic: PropTypes.bool,
};

StyledParagraph.defaultProps = {
  margin: undefined,
  marginTop: 'regular',
  marginBottom: 'regular',
  marginLeft: 'none',
  marginRight: 'none',
  paddingTop: 'none',
  paddingBottom: 'none',
  paddingLeft: 'none',
  paddingRight: 'none',
  padding: undefined,
  textTransform: 'none',
  textAlign: 'inherit',
  textAlignLargeScreen: '',
  italic: false,
  hideLargeScreen: false,
  hideSmallScreen: false,
  breakSize: 'tablet',
  fontSize: 'small',
  fontSizeLargeScreen: undefined,
};

const Paragraph = StyledParagraph;

export default Paragraph;
