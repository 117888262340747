import {
  fork,
  take,
  cancel,
} from 'redux-saga/effects';

const cancellableTakeLatest = (
  patternOrChannel,
  cancelPatternOrChannel,
  saga,
  ...args
) => fork(function* _() {
  let task;
  while (true) {
    const action = yield take([patternOrChannel, cancelPatternOrChannel]);

    if (task) yield cancel(task);

    if (action.type !== cancelPatternOrChannel) {
      task = yield fork(saga, ...args.concat(action));
    }
  }
});

export default cancellableTakeLatest;
