import styled from 'styled-components';

const StyledInputBlock = styled('div')`
  max-width: 30rem;
  align-self: center;
  display: flex;
  flex-direction: ${(props) => (props.horizontal ? 'row' : 'column')};
  justify-content: ${(props) => (props.horizontal ? 'space-between' : 'flex-start')};
  align-items: stretch;

  ${(props) => !props.inline && `
    width: 100%;
    margin: 0.5rem 0;
  `}
`;

export default StyledInputBlock;
