import React from 'react';
import PropTypes from 'prop-types';

import Anchor from './Anchor';

const flattenNumber = (phoneNumber) => phoneNumber.replace(/[^0-9.]/g, '');

const PhoneNumber = ({
  underlined,
  phoneNumber,
}) => {
  const phoneLink = `tel:${flattenNumber(phoneNumber)}`;
  return (
    <>
      Tel:&nbsp;
      <Anchor underlined={underlined} href={phoneLink}>
        {phoneNumber}
      </Anchor>
      &nbsp;(måndag-fredag kl. 09.00 till 18.00)
    </>
  );
};

PhoneNumber.propTypes = {
  underlined: PropTypes.bool,
  phoneNumber: PropTypes.string.isRequired,
};

PhoneNumber.defaultProps = {
  underlined: false,
};

export default PhoneNumber;
