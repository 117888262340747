import React from 'react';
import PropTypes from 'prop-types';

import Headline from '../../elements/Headline';
import Paragraph from '../../elements/Paragraph';
import PhoneNumber from '../../elements/PhoneNumber';
import ModalContent from './common/ModalContent';
import ModalBase from './common/ModalBase';

import moankInfo from '../../../constants/moankpayInfo';

const ErrorModal = ({ title, message }) => (
  <ModalBase>
    <ModalContent>
      <Headline as="h3" fontSize="small" textAlign="center">{title}</Headline>
      <Paragraph textAlign="center">{message}</Paragraph>
    </ModalContent>
  </ModalBase>
);

ErrorModal.propTypes = {
  title: PropTypes.string,
  message: PropTypes.node,
};

ErrorModal.defaultProps = {
  title: 'Något gick fel',
  message:
  <>
    <Paragraph>
      Något gick fel och vi ber om ursäkt för detta.
      Om problemet kvarstår, vänligen kontakta kundtjänst.
    </Paragraph>
    <Paragraph>
      <PhoneNumber phoneNumber={moankInfo.MOANK_NUMBER} />
    </Paragraph>
  </>,
};

export default ErrorModal;
