import {
  all,
  call,
  put,
  takeEvery,
  select,
  delay,
  takeLatest,
} from 'redux-saga/effects';
import { push } from 'redux-first-history';
import * as Sentry from '@sentry/react';
import queryString from 'query-string';

import {
  types,
  startBankLoginSessionSuccess,
  updateBankLoginSession,
  retrieveBankData,
  retrieveBankDataSuccess,
  retrieveBankDataFailure,
  validateField,
  startBankLoginSessionFailure,
  signApplicationSuccess,
  signApplicationFailure,
} from './actions';

import { hideModal, showModal, restoreUiReducer } from '../ui/actions';

import {
  startBankLoginSession,
  retrieveBankData as retrieveBankDataCall,
  getSignApplicationInfo as getSignApplicationInfoCall,
  signApplication as signApplicationCall,
  fetchBankLoginSession,
} from '../../apiClient/bank';

import cancellableTakeLatest from '../../utils/cancellableTakeLatest';
import validators from '../../utils/validators';
import errorLogger from '../../utils/errorLogger';
import getUrl from '../../utils/getUrl';

import bankLoginStatuses from '../../constants/bankLoginStatuses';
import bankIdTypes from '../../constants/bankIdTypes';
import tinkErrors from '../../constants/tinkErrors';
import routes from '../../constants/routes';

import modalTypes from '../../components/modules/modals/common/types';

function* startBankLoginSessionSaga({ provider, parameters }) {
  const uiReducer = yield select((state) => state.ui);
  try {
    const { redirectLink, sessionId } = yield call(
      startBankLoginSession,
      provider,
      {
        ...parameters,
        data: { uiReducer },
      },
    );
    yield put(startBankLoginSessionSuccess({ sessionId }));
    global.location = redirectLink;
  } catch (e) {
    yield put(startBankLoginSessionFailure());
    yield put(showModal({ type: modalTypes.ERROR }));
    errorLogger(e);
  }
}

function* handleBankLoginRedirect() {
  const location = yield select((state) => state.router.location);
  try {
    const {
      code,
      error,
      message,
      state: sessionId,
    } = queryString.parse(location.search);
    const { bankLoginSession } = yield call(
      fetchBankLoginSession,
      sessionId,
    );
    const { uiReducer } = bankLoginSession.data;
    yield put(restoreUiReducer(uiReducer));

    const applicationId = yield select((state) => state.ui.application.id);
    if (!applicationId) throw new Error('Missing applicationId after Tink callback');

    const { provider } = bankLoginSession;

    if (error || !code) {
      const cleanedMessage = message && message.replace(/.*message: /, '');
      yield put(updateBankLoginSession(bankLoginStatuses.FAILURE, cleanedMessage));

      if (error === tinkErrors.USER_CANCELLED) {
        // Nothing to do, this is a user initiated action
      } else if (error === tinkErrors.AUTHENTICATION_ERROR) {
        yield put(showModal({
          type: modalTypes.ERROR,
          props: {
            title: 'Legitimeringen misslyckades',
            message: `Något gick fel vid legitimeringen. Vi fick följande meddelande från din bank: ${cleanedMessage} Om du har problem med att ansluta din bank, kontakta vår kundtjänst.`,
          },
        }));
      } else if (error === tinkErrors.TEMPORARY_ERROR) {
        yield put(showModal({
          type: modalTypes.ERROR,
          props: {
            title: 'Tillfälligt fel',
            message: 'Ett tillfälligt fel uppstod med din bank eller vår partner Tink. Vänligen försök igen. Om problemet kvarstår, kontakta vår kundtjänst.',
          },
        }));
      } else {
        yield put(showModal({ type: modalTypes.ERROR }));
        Sentry.withScope((scope) => {
          scope.setExtra('message', message);
          scope.setExtra('error', error);
          Sentry.captureMessage('Tink auth failed');
        });
      }
      yield put(push(getUrl(routes.LANDING_PAGE, { applicationId })));
    } else {
      yield put(updateBankLoginSession(bankLoginStatuses.SUCCESS, '', code));
      yield put(retrieveBankData(provider, code, sessionId));
    }
  } catch (e) {
    const applicationId = yield select((state) => state.ui.application.id);
    yield put(showModal({ type: modalTypes.ERROR }));
    yield put(push(getUrl(routes.LANDING_PAGE, { applicationId })));
    errorLogger(e);
  }
}

const retrieveDataErrorMessages = {
  INVALID_TOKEN: 'Anslutningen till din bank misslyckades. Vi ber om ursäkt för detta. Vänligen försök igen. Om problemet kvarstår, kontakta kundtjänst.',
  INVALID_CUSTOMER_ID: 'Det verkar som att länken är felaktig. Vi ber om ursäkt för detta. Vänligen kontakta kundtjänst för att få en ny länk.',
  INVALID_IDENTITY: 'Det verkar som att du försöker ansluta ett konto som inte tillhör dig. Vänligen försök igen med ett konto som tillhör dig.',
  INVALID_ACCOUNTS: 'Tyvärr kan vi inte hitta något giltigt konto hos banken du valde. Vänligen välj en annan bank för utbetalning och återbetalning av lånet.',
};

function* retrieveBankDataSaga({ provider, token, sessionId }) {
  const socialSecurityNumber = yield select(
    (state) => state.ui.application.socialSecurityNumber,
  );
  const customerId = yield select((state) => state.ui.application.applicants[0].id);
  const parameters = {
    socialSecurityNumber,
    customerId,
    tinkReportType: 'bank-accounts',
  };
  const applicationId = yield select((state) => state.ui.application.id);
  try {
    const data = {
      provider,
      token,
      parameters,
      sessionId,
    };
    const { accounts, reportId } = yield call(retrieveBankDataCall, data);
    yield put(retrieveBankDataSuccess(accounts, reportId));

    yield put(push(getUrl(routes.BANK_ACCOUNT_SELECTION, { applicationId })));
  } catch (e) {
    yield put(retrieveBankDataFailure());
    const message = (e.response && e.response.body && e.response.body.message) || '';
    const handledErrorMessage = retrieveDataErrorMessages[message];
    yield put(showModal({
      type: modalTypes.ERROR,
      props: {
        title: 'Anslutningen misslyckades',
        message: handledErrorMessage,
      },
    }));
    if (!handledErrorMessage) errorLogger(e);
    yield put(push(getUrl(routes.LANDING_PAGE, { applicationId })));
  }
}

function* validateFieldSaga(action) {
  const {
    field,
    value,
  } = action;

  yield put(validateField(field, validators[field](value)));
}

function* signApplicationSaga() {
  const applicationId = yield select((state) => state.ui.application.id);
  const signerId = yield select((state) => state.ui.application.applicants[0].id);

  const payload = { signingPartyType: 'applicant' };

  try {
    const token = yield call(getSignApplicationInfoCall, applicationId, signerId, payload);

    const bankIdType = yield select((state) => state.ui.bankIdType);
    if (bankIdType === bankIdTypes.SAME_DEVICE) {
      global.location = `bankid:///?autostarttoken=${token.autoStartToken}&redirect=null`;
    }
    let res = { pending: true };
    while (res.pending) {
      yield delay(3000);
      try {
        res = yield call(
          signApplicationCall,
          applicationId,
          signerId,
          payload,
          token.orderRef,
        );
      } catch (e) {
        if (!e.message.startsWith('Request has been terminated')) throw e;
      }
    }

    yield put(signApplicationSuccess());
    yield put(hideModal());
    yield put(push(getUrl(routes.THANK_YOU_PAGE, { applicationId })));
  } catch (e) {
    yield put(signApplicationFailure());
    yield put(showModal({ type: modalTypes.ERROR }));
  }
}

export default function* bankSaga() {
  yield all([
    cancellableTakeLatest(
      types.START_BANK_LOGIN_SESSION,
      types.BANK_RESET,
      startBankLoginSessionSaga,
    ),
    takeEvery(types.HANDLE_BANK_LOGGIN_REDIRECT, handleBankLoginRedirect),
    cancellableTakeLatest(
      types.RETRIEVE_BANK_DATA,
      types.BANK_RESET,
      retrieveBankDataSaga,
    ),
    takeEvery(types.UPDATE_FIELD, validateFieldSaga),
    takeLatest(types.SIGN_APPLICATION, signApplicationSaga),
  ]);
}
