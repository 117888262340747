import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import StepperItem from './StepperItem';
import style from '../../constants/style';
import paymentFlow from '../../constants/paymentFlow';

const getNavPadding = (length) => `${100 / length / 2}% - 0.5rem`;

const completedFlow = ({ length, current }) => {
  const done = current ? ((current) / (length - 1)) : 0;
  return `(100% - 2 * (${getNavPadding(length)})) * ${done}`;
};

const StyledStepper = styled('nav')`
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  justify-content: space-between;
  margin: ${style.margin.medium} auto;
  position: relative;
  width: 100%;
  max-width: ${(props) => Math.min(props.length * 5, 40)}rem;

  &::before {
    content: '';
    z-index: ${style.zLevels.normal};
    position: absolute;
    top: 1.2rem;
    left: calc(${(props) => getNavPadding(props.length)});
    border-top: 2px solid ${style.colours.primaryDark};
    width: calc(${completedFlow});
    transform: translateY(-50%);
  }

  &::after {
    content: '';
    z-index: ${style.zLevels.behind};
    position: absolute;
    top: 1.2rem;
    left: calc(${(props) => getNavPadding(props.length)});
    border-top: 2px solid ${style.colours.secondaryDarkGray4};
    width: calc(100% - 2 * (${(props) => getNavPadding(props.length)}));
    transform: translateY(-50%);
  }
`;

const Stepper = ({ step }) => {
  const currentIndex = paymentFlow.findIndex(({ step: paymentStep }) => paymentStep === step);
  return (
    <StyledStepper length={paymentFlow.length} current={currentIndex}>
      {paymentFlow.map((stepperStep, index) => (
        <StepperItem
          length={paymentFlow.length}
          active={index === currentIndex}
          done={index < currentIndex}
          index={index + 1}
          step={stepperStep}
          key={`${stepperStep.title}`}
        />
      ))}
    </StyledStepper>
  );
};

Stepper.propTypes = {
  step: PropTypes.string.isRequired,
};

export default Stepper;
