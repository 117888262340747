import React, { useCallback } from 'react';
import { useDispatch } from 'react-redux';

import { showModal } from '../../../redux/ui/actions';
import modalTypes from '../../modules/modals/common/types';

import Icon from '../Icon';
import questionMark from '../../../assets/question-mark.svg';
import Anchor from '../Anchor';
import FlexBox from '../../containers/FlexBox';

const InfoButton = () => {
  const dispatch = useDispatch();
  const showInfoModal = useCallback((e) => {
    e.preventDefault();
    dispatch(showModal({
      type: modalTypes.INFO_MODAL,
    }));
  }, [dispatch]);
  return (
    <FlexBox display="inline-flex" verticalAlign="-0.185rem">
      <Anchor onClick={showInfoModal}>
        <Icon icon={questionMark} iconWidth="1.2rem" />
      </Anchor>
    </FlexBox>
  );
};
export default InfoButton;
