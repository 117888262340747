import React from 'react';
import PropTypes from 'prop-types';

import Cross from '../../../../assets/cross.svg';

import FlexBox from '../../../containers/FlexBox';
import Icon from '../../../elements/Icon';

const CloseButton = React.forwardRef(({ onClick }, ref) => (
  <FlexBox>
    <Icon
      onClick={onClick}
      ref={ref}
      icon={Cross}
      margin="none"
      top="0.5rem"
      right="0.5rem"
    />
  </FlexBox>
));

CloseButton.propTypes = {
  onClick: PropTypes.func.isRequired,
};

export default CloseButton;
