import styled from 'styled-components';

import Arrow from '../../../assets/arrow.svg';

const StyledSelectWrapper = styled('div')`
  position: relative;

  &:after {
    content: url(${Arrow});
    font: normal normal normal 17px/1 FontAwesome;
    right: 1rem;
    top: 1rem;
    position: absolute;
    pointer-events: none;
  }
`;

export default StyledSelectWrapper;
