import configs from '../configs';

const { apiUrl } = configs;

const api = {
  BANK_START_LOGIN_SESSION: `${apiUrl}/bank/startLoginSession`,
  BANK_RETRIEVE_DATA: `${apiUrl}/bank/retrieveData`,
  BANK_CHECK_LOGIN_SESSION: (sessionId) => `${apiUrl}/bank/checkLoginSession/${sessionId}`,
  GET_SIGN_APPLICATION_INFO: (applicationId, signerId) => `${apiUrl}/application/${applicationId}/fetch-sign-order/${signerId}`,
  SIGN_APPLICATION: (applicationId, signerId) => `${apiUrl}/application/${applicationId}/verify-signature/${signerId}`,
  APPLICATION_RETRIEVE_DATA: (applicationId) => `${apiUrl}/application/fetch/${applicationId}`,
  PARTNER_RETRIEVE_DETAILS: (partnerId) => `${apiUrl}/partner/${partnerId}/unauthenticated`,
  SUBMIT_SIGN_DATA: (applicationId, signerId) => `${apiUrl}/application/${applicationId}/post-decision/${signerId}`,
  FETCH_PUBLIC_SETTINGS: `${apiUrl}/settings/public`,
};

export default api;
