import React, { useCallback } from 'react';
import { useSelector, useDispatch } from 'react-redux';

import Paragraph from '../elements/Paragraph';
import Input from '../elements/Input';

import { updateField } from '../../redux/bank/actions';

const AccountSelect = () => {
  const dispatch = useDispatch();
  const bankAccountId = useSelector((state) => state.bank.bankAccountId);
  const autogiroSignableAccounts = useSelector((state) => state.bank.accounts);

  const onChange = useCallback((field, value) => {
    dispatch(updateField(field, value));
  }, [dispatch]);

  if (!autogiroSignableAccounts) return null;

  if (!autogiroSignableAccounts.length) {
    return <Paragraph>Du har inget bankkonto att signera. Välj en annan bank.</Paragraph>;
  }

  return (
    <Input
      _id="bankAccountId"
      inputType="select"
      value={bankAccountId.value}
      onChange={onChange}
      values={autogiroSignableAccounts}
      hasError={bankAccountId.valid === false}
      isSuccess={bankAccountId.valid}
      label="Välj ditt konto"
      defaultOption="Välj alternativ"
    />
  );
};

export default AccountSelect;
