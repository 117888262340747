import React, { useContext } from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';

import Headline from '../../../elements/Headline';
import ModalOptionsContext from './context';
import FlexBox from '../../../containers/FlexBox';

import style from '../../../../constants/style';

const modalPaddings = {
  mobile: {
    top: '0.5rem',
    horizontal: '1rem',
    bottom: '2rem',
  },
  laptop: {
    top: '1rem',
    horizontal: '4rem',
    bottom: '2.5rem',
  },
};

const getTopPadding = (
  { closeButtonHidden },
  size,
) => (closeButtonHidden ? modalPaddings[size].bottom : modalPaddings[size].top);

const StyledModalContent = styled(FlexBox)`
  overflow-y: auto;
  -webkit-overflow-scrolling: touch;
  align-items: stretch;
  flex-direction: column;

  padding: ${(props) => getTopPadding(props, 'mobile')} ${modalPaddings.mobile.horizontal} ${modalPaddings.mobile.bottom};

  ${style.media.laptop`
    padding: ${(props) => getTopPadding(props, 'laptop')} ${modalPaddings.laptop.horizontal} ${modalPaddings.laptop.bottom};
  `}
`;

const ModalContent = ({
  children,
  title,
}) => {
  const options = useContext(ModalOptionsContext);

  return (
    <StyledModalContent closeButtonHidden={options.preventClosing}>
      {title && <Headline as="h3" fontSize="small" textAlign="center" fontWeight="semiBold">{title}</Headline>}
      {children}
    </StyledModalContent>
  );
};

ModalContent.propTypes = {
  children: PropTypes.node.isRequired,
  title: PropTypes.string,
};

ModalContent.defaultProps = {
  title: null,
};

export default ModalContent;
