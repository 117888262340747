import styled from 'styled-components';
import style from '../../constants/style';

const StyledLabel = styled('label')`
  color: ${(props) => (props.hasError ? style.colours.tertiaryFailure : style.colours.primaryDark)};
  font-weight: ${style.fontWeight.regular};
  margin: 0.25rem 0;
`;

const Label = StyledLabel;

export default Label;
