import styled from 'styled-components';
import style from '../../constants/style';

export const StyledLi = styled('li')`
  margin: 0;
  font-size: ${style.fontSize.small};
  line-height: 1.5;
`;

const Li = StyledLi;

export default Li;
