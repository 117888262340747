import React, { useEffect, useCallback } from 'react';

import { useDispatch, useSelector } from 'react-redux';
import { push } from 'redux-first-history';
import { bankReset, updateField } from '../redux/bank/actions';

import Page from './containers/Page';
import Headline from './elements/Headline';
import Loader from './elements/Loader';
import Stepper from './elements/Stepper';
import Button from './elements/Button';

import paymentSteps from '../constants/paymentSteps';
import Paragraph from './elements/Paragraph';
import PartnerLogo from './PartnerLogo';
import List from './elements/List';
import Li from './elements/Li';
import Input from './elements/Input';
import FlexBox from './containers/FlexBox';
import Anchor from './elements/Anchor';
import useFetchApplication from '../utils/useFetchApplication';
import { showModal } from '../redux/ui/actions';
import modalTypes from './modules/modals/common/types';
import routes from '../constants/routes';
import getUrl from '../utils/getUrl';

const APPLE_CARE_SERVICES = 'AppleCare Services';
const SURE_THING = 'Sure Thing';

const LandingPage = () => {
  const dispatch = useDispatch();
  const canBeSigned = useSelector((state) => state.ui.application.applicationStep === 'agreement-sent');
  const applicationId = useSelector((state) => state.ui.application.id);
  const termsApproved = useSelector((state) => state.bank.termsApproved);
  const canBeProfiled = useSelector((state) => state.bank.canBeProfiled);

  useEffect(() => {
    dispatch(bankReset());

    if (applicationId && !canBeSigned) {
      dispatch(showModal({
        type: modalTypes.ERROR,
        props: {
          title: 'Felaktig länk',
          message: 'Denna länk verkar redan ha använts eller upphört att gälla.',
        },
      }));
    }
  }, [dispatch, canBeSigned, applicationId]);

  const toBankSelect = useCallback(
    () => dispatch(push(getUrl(routes.BANK_SELECT, { applicationId }))),
    [dispatch, applicationId],
  );

  const onChange = useCallback((field, value) => {
    dispatch(updateField(field, value));
  }, [dispatch]);

  useFetchApplication();
  const loading = useSelector((state) => state.ui.application.loading);

  const termsAccepted = !!(termsApproved.valid && canBeProfiled.valid);

  return (
    <Page>
      <Stepper step={paymentSteps.INFORMATION} />
      {loading ? <Loader /> : (
        <>
          <PartnerLogo />
          <Headline
            as="h3"
            fontSize="small"
            textAlign="center"
            marginTop="medium"
            marginBottom="regular"
          >
            Försäkringsuppgifter
          </Headline>
          <FlexBox
            flexDirection="column"
            alignSelf="center"
            maxWidth="29rem"
          >
            <Paragraph fontSize="small" textAlign="center" fontWeight="bold">Försäkring med {APPLE_CARE_SERVICES} & elektroniska produkter</Paragraph>
            <FlexBox marginBottom="regular">
              <List>
                <Li>Försäkringen täcker oavsiktliga skador samt stöld</Li>
                <Li>Försäkringen täcker inte förlust eller skador som inte påverkar
                  produktens funktionalitet och den omfattar inte uppsåtliga skador.
                </Li>
                <Li>Försäkringen tillämpar självrisk (fr. 249 kr per skada).
                  Du kan närsomhelst välja att säga upp försäkringen under dess giltighetstid.
                </Li>
                <Li>
                  Försäkringen med {APPLE_CARE_SERVICES} omfattar även 50GB iCloud utan extra
                  kostnad, 24/7 support, batterigaranti samt möjlighet att få hjälp i hela världen
                  via auktoriserade Apple-verkstäder.
                </Li>
              </List>
            </FlexBox>
            <FlexBox marginBottom="huge" flexDirection="column">
              <Paragraph fontSize="small" fontWeight="bold">Förköpsinformation</Paragraph>
              <List>
                <Li>Förköpsinformation till försäkring med&nbsp;
                  <Anchor
                    underlined
                    target="_blank"
                    href="https://www.mediamarkt.se/static/pdf/F%C3%B6rk%C3%B6psinformatin_AppleCare_Services_2022.pdf"
                  >
                    {APPLE_CARE_SERVICES}
                  </Anchor>
                </Li>
                <Li>Förköpsinformation till försäkring med&nbsp;
                  <Anchor
                    underlined
                    target="_blank"
                    href="https://www.mediamarkt.se/static/pdf/F%C3%B6rk%C3%B6psinformatin_surething_2022.pdf"
                  >
                    {SURE_THING}
                  </Anchor>
                </Li>
              </List>
            </FlexBox>
            <Input
              _id="termsApproved"
              inputType="checkbox"
              value={termsApproved.value}
              onChange={onChange}
              hasError={false}
            >
              <Paragraph>
                Jag har tagit del av försäkringsvillkor för&nbsp;
                <Anchor
                  underlined
                  target="_blank"
                  href="https://www.mediamarkt.se/static/pdf/F%C3%B6rs%C3%A4kring_med_AppleCare_Services_villkor_2022.pdf"
                >
                  försäkring med {APPLE_CARE_SERVICES}&nbsp;
                </Anchor>
                och/eller&nbsp;
                <Anchor
                  underlined
                  target="_blank"
                  href="https://www.mediamarkt.se/static/pdf/F%C3%B6rs%C3%A4kring_med_surething_villkor_2022.pdf"
                >
                  {SURE_THING}
                </Anchor>
              </Paragraph>
            </Input>
            <Input
              _id="canBeProfiled"
              inputType="checkbox"
              value={canBeProfiled.value}
              onChange={onChange}
              hasError={false}
            >
              <Paragraph>
                Jag ger mitt medgivande till månadsvis betalning via autogiro och bekräftar
                att jag har tagit del av&nbsp;
                <Anchor target="_blank" underlined href="https://signup.moank.se/assets/bankgiro/direct-debit/Moank_autogiro_villkor.pdf">Bankgiros villkor</Anchor> för autogiro.
              </Paragraph>
            </Input>
          </FlexBox>
          <FlexBox alignItems="center" flexDirection="column" margin="regular">
            <Button
              onClick={toBankSelect}
              disabled={!termsAccepted}
            >
              Nästa
            </Button>
          </FlexBox>
        </>
      )}
    </Page>
  );
};

export default LandingPage;
