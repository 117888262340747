import React, { useCallback, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { selectBankIdType } from '../../../redux/ui/actions';
import { signApplication } from '../../../redux/bank/actions';
import bankIdTypes from '../../../constants/bankIdTypes';
import Button from '../../elements/Button';
import Loader from '../../elements/Loader';
import ModalContent from './common/ModalContent';
import Icon from '../../elements/Icon';
import Paragraph from '../../elements/Paragraph';
import BankIdLogo from '../../../assets/bankid.svg';
import ModalBase from './common/ModalBase';

const BankIdModal = () => {
  const dispatch = useDispatch();
  const bankIdLoading = useSelector((state) => state.ui.bankIdLoading);
  const socialSecurityNumber = useSelector((state) => state.ui.application.socialSecurityNumber);
  const bankAccountId = useSelector((state) => state.bank.bankAccountId);

  const [isStarting, setIsStarting] = useState(true);

  const triggerBankIdAction = useCallback(() => {
    if (!bankAccountId.valid || !socialSecurityNumber) return;

    dispatch(signApplication(bankAccountId.value, socialSecurityNumber));
  }, [dispatch, bankAccountId.valid, bankAccountId.value,
    socialSecurityNumber]);

  useEffect(() => {
    if (isStarting && bankIdLoading) {
      dispatch(selectBankIdType(bankIdTypes.OTHER_DEVICE));
      triggerBankIdAction();
    }
    setIsStarting(false);
  }, [isStarting, bankIdLoading, dispatch, triggerBankIdAction]);

  const handleClick = useCallback((bankIdType) => (e) => {
    e.preventDefault();

    dispatch(selectBankIdType(bankIdType));
    triggerBankIdAction();
  }, [dispatch, triggerBankIdAction]);

  return (
    <ModalBase>
      {bankIdLoading ? (
        <ModalContent title="Starta BankID">
          <Paragraph textAlign="center">
            <Icon icon={BankIdLogo} iconWidth="6rem" />
          </Paragraph>
          <Loader />
        </ModalContent>
      ) : (
        <ModalContent title="Signera med BankID">
          <Paragraph textAlign="center">
            <Button onClick={handleClick(bankIdTypes.SAME_DEVICE)}>
              Öppna på samma enhet
            </Button>
          </Paragraph>
          <Paragraph textAlign="center">
            <Button onClick={handleClick(bankIdTypes.OTHER_DEVICE)} type="secondary">
              Öppna på en annan enhet
            </Button>
          </Paragraph>
        </ModalContent>
      )}
    </ModalBase>
  );
};

export default BankIdModal;
