import React from 'react';

import { useSelector } from 'react-redux';

import FlexBox from './containers/FlexBox';
import Logo from './elements/Logo';
import Paragraph from './elements/Paragraph';

const PartnerLogo = () => {
  const logoKey = useSelector((state) => state.ui.partner.logoKey);

  return (
    <FlexBox flexDirection="column">
      <Logo alt="" icon={logoKey} height="2rem" marginBottom="none" />
      <Paragraph textAlign="center" fontSize="medium">I samarbete med Moank.</Paragraph>
    </FlexBox>
  );
};

export default PartnerLogo;
