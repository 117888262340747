import request from 'superagent';
import configs from '../configs';

const { apiKey } = configs;

const requestBuilder = async ({
  method,
  url,
  data,
  queryParams,
}) => {
  if (!request[method]) throw new Error('Requested method is invalid');

  let formattedUrl = url;
  if (queryParams) {
    const formattedUrlParams = new URLSearchParams(queryParams);
    formattedUrl = `${formattedUrl}?${formattedUrlParams.toString()}`;
  }

  const preparedRequest = request[method](formattedUrl)
    .set('X-API-Key', apiKey)
    .set('Accept', 'application/json');

  if (data) preparedRequest.send(data);
  return preparedRequest;
};

export default requestBuilder;
