import styled from 'styled-components';

const ALIGN_LIST_WITH_TEXT_PADDING = '1.25rem';

export const StyledList = styled('ul')`
  display: flex;
  flex-direction: column;
  align-self: 'center';
  margin: 0;
  padding-left: ${ALIGN_LIST_WITH_TEXT_PADDING};

`;

const List = StyledList;

export default List;
