import React from 'react';
import PropTypes from 'prop-types';

import Anchor from './Anchor';
import Icon from './Icon';
import FlexBox from '../containers/FlexBox';

import Arrow from '../../assets/arrow.svg';

const BackButton = ({
  previous,
}) => (
  <FlexBox marginTop="huge" justifyContent="center">
    <Anchor to={previous}>
      <Icon fillColour="primaryDark" icon={Arrow} iconWidth="2rem" iconHeight="2rem" rotate={90} />
      Tillbaka
    </Anchor>
  </FlexBox>
);

BackButton.propTypes = {
  previous: PropTypes.string.isRequired,
};

export default BackButton;
