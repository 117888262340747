import React, { useCallback, useEffect, useState } from 'react';
import PropTypes from 'prop-types';

import StyledInput, { StyledUnitToInput } from './StyledInput';
import StyledInputBlock from './StyledInputBlock';

import Select from '../Select';
import StyledDefaultOption from './StyledDefaultOption';
import Checkbox from '../Checkbox';
import Label from '../Label';
import StyledLabel from './StyledLabel';
import StyledSelectWrapper from './StyledSelectWrapper';

const noop = (value) => value;

const Input = React.forwardRef(({
  _id,
  inputType,
  values,
  children,
  label,
  type,
  value,
  disabled,
  hasError,
  isSuccess,
  placeholder,
  min,
  max,
  defaultOption,
  onChange,
  onBlur,
  parser,
  formatter,
  suffix,
  inline,
  textAlign,
  inputWidth,
}, ref) => {
  const [formattedValue, setFormattedValue] = useState(formatter(value));
  useEffect(() => {
    setFormattedValue((currentFormattedValue) => {
      if (formatter(value) !== formatter(parser(currentFormattedValue))) return formatter(value);
      return currentFormattedValue;
    });
  }, [value, formatter, parser]);

  const onChangeValue = useCallback((e) => {
    const inputValue = inputType === 'checkbox' ? e.target.checked : e.target.value;
    setFormattedValue(inputValue);
    const parsedValue = parser(inputValue);

    onChange(_id, parsedValue, { min, max });
  }, [_id, inputType, parser, onChange, min, max]);

  const onBlurInput = useCallback((e) => {
    if (!onBlur) return;

    const inputValue = inputType === 'checkbox' ? e.target.checked : e.target.value;
    const parsedValue = parser(inputValue);

    setFormattedValue(formatter(parsedValue));
    onBlur(_id, parsedValue, { min, max });
  }, [_id, inputType, parser, formatter, onBlur, min, max]);

  let labelComponent = null;

  let input = (
    <StyledInput
      type={type}
      id={_id}
      ref={ref}
      placeholder={placeholder}
      onChange={onChangeValue}
      onBlur={onBlurInput}
      value={formattedValue}
      disabled={disabled}
      hasError={hasError}
      isSuccess={isSuccess}
      min={min && min.toString()}
      max={max && max.toString()}
      textAlign={textAlign}
      inputWidth={inputWidth}
    />
  );

  if (inputType === 'select') {
    const options = values.map((obj) => (
      <option
        key={`key-${obj.value}-${obj.label}`}
        value={obj.value}
        disabled={obj.disabled}
      >
        {obj.label}
      </option>
    ));
    input = (
      <StyledSelectWrapper>
        <Select
          as="select"
          id={_id}
          ref={ref}
          onChange={onChangeValue}
          onBlur={onBlurInput}
          value={formattedValue || ''}
          disabled={disabled}
          hasError={hasError}
          inputWidth={inputWidth}
        >
          <StyledDefaultOption key="key" disabled={!!formattedValue}>{defaultOption}</StyledDefaultOption>
          {options}
        </Select>
      </StyledSelectWrapper>
    );
  }

  if (inputType === 'checkbox') {
    input = (
      <Checkbox
        hasError={hasError}
        isSuccess={isSuccess}
        checked={formattedValue}
      >
        <input
          id={_id}
          type="checkbox"
          name={_id}
          ref={ref}
          onChange={onChangeValue}
          onBlur={onBlurInput}
          checked={formattedValue}
          disabled={disabled}
        />
      </Checkbox>
    );
    labelComponent = children ? (
      <Label htmlFor={_id}>
        {children}
      </Label>
    ) : null;
  }

  if (label) {
    labelComponent = (
      <StyledLabel htmlFor={_id}>
        {label}
        {' '}
      </StyledLabel>
    );
  }

  if (suffix) {
    input = (
      <StyledUnitToInput
        unit={suffix}
        isSuccess={isSuccess}
        inputWidth={inputWidth}
      >
        {input}
      </StyledUnitToInput>
    );
  }

  return (
    <StyledInputBlock horizontal={inputType === 'checkbox'} inline={inline}>
      {labelComponent}
      {input}
    </StyledInputBlock>
  );
});

const valueType = PropTypes.oneOfType([
  PropTypes.string,
  PropTypes.bool,
  PropTypes.number,
]);

Input.propTypes = {
  _id: PropTypes.string,
  inputType: PropTypes.oneOf(['select', 'checkbox', 'input', 'radio']),
  hasError: PropTypes.bool.isRequired,
  isSuccess: PropTypes.bool,
  onChange: PropTypes.func.isRequired,
  onBlur: PropTypes.func,
  type: PropTypes.string,
  value: valueType,
  placeholder: PropTypes.string,
  disabled: PropTypes.bool,
  min: PropTypes.number,
  max: PropTypes.number,
  defaultOption: PropTypes.string,
  parser: PropTypes.func,
  formatter: PropTypes.func,
  suffix: PropTypes.string,
  inline: PropTypes.bool,
  textAlign: PropTypes.oneOf(['left', 'right']),
  inputWidth: PropTypes.number,
  values: PropTypes.arrayOf(PropTypes.shape({
    value: valueType,
    label: PropTypes.node,
  })),
  label: PropTypes.string,
  children: PropTypes.node,
};

Input.defaultProps = {
  _id: undefined,
  inputType: 'input',
  isSuccess: false,
  type: 'text',
  label: '',
  value: '',
  placeholder: '',
  disabled: false,
  min: undefined,
  max: undefined,
  defaultOption: 'Välj alternativ',
  parser: noop,
  formatter: noop,
  suffix: undefined,
  onBlur: undefined,
  inline: false,
  textAlign: 'left',
  inputWidth: undefined,
  values: [],
  children: null,
};

export default React.memo(Input);
