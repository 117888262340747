/* eslint-disable react/jsx-props-no-spreading */
import React from 'react';
import { useSelector } from 'react-redux';

import ModalWrapper from './ModalWrapper';

import types from './types';

import BankIdModal from '../BankIdModal';
import ErrorModal from '../ErrorModal';
import InfoModal from '../InfoModal';

const modals = {
  [types.BANK_ID]: BankIdModal,
  [types.ERROR]: ErrorModal,
  [types.INFO_MODAL]: InfoModal,
};

const getModal = (modal) => {
  const Modal = modals[modal.type];
  if (!Modal) return null;

  return (
    <React.Suspense fallback={null}>
      <Modal {...modal.props} />
    </React.Suspense>
  );
};

const ModalHost = () => {
  const modal = useSelector((state) => state.ui.modal);

  return (modal && modal.type
    ? (<ModalWrapper modalOptions={modal.options}>{getModal(modal)}</ModalWrapper>)
    : null);
};

export default ModalHost;
