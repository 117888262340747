import { css } from 'styled-components';
import { rgba } from 'polished';

import screenTypes from './screenTypes';

const colours = {
  primaryDark: '#181A1C',
  primaryLight: '#FAFAFA',
  secondaryLightGray1: '#FFFFFF',
  secondaryLightGray2: '#F6F6F6',
  secondaryLightGray3: '#F1F1EF',
  secondaryLightGray4: '#EBEBE9',
  secondaryLightGray5: '#E1E1DF',
  secondaryDarkGray1: '#2F3131',
  secondaryDarkGray2: '#4E5050',
  secondaryDarkGray3: '#6C6C6C',
  secondaryDarkGray4: '#7D7D7D',
  tertiarySuccess: '#59715A',
  tertiaryFailure: '#9E5C56',
  tertiaryPurple: '#84577E',
  tertiaryBlue: '#2E63B2',
};

const fontFamily = {
  main: 'aktiv-grotesk, sans-serif',
};

const fontSize = {
  tiny: '0.75rem',
  smaller: '0.875rem',
  small: '1rem',
  regular: '1.125rem',
  medium: '1.25rem',
  big: '1.5rem',
  bigger: '1.75rem',
  huge: '2rem',
  gigantic: '4rem',
};

const headlineFontSizes = {
  mobile: {
    huge: '4rem',
    big: '2.75rem',
    normal: '2rem',
    small: '1.5rem',
    tiny: '1.125rem',
  },
  laptop: {
    huge: '4.5rem',
    big: '3.5rem',
    normal: '3rem',
    small: '2rem',
    tiny: '1.25rem',
  },
};

const fontWeight = {
  light: '300',
  regular: '400',
  semiBold: '500',
  bold: '700',
};

const margin = {
  none: '0rem',
  tiny: '0.25rem',
  small: '0.5rem',
  regular: '1rem',
  medium: '1.5rem',
  big: '2rem',
  bigger: '2.5rem',
  huge: '3rem',
  hugeer: '4rem',
  gigantic: '5rem',
  godlike: '6rem',
};

const padding = {
  none: '0rem',
  tiny: '0.25rem',
  small: '0.5rem',
  regular: '1rem',
  medium: '1.5rem',
  big: '2rem',
  bigger: '2.5rem',
  huge: '3rem',
  hugeer: '4rem',
  gigantic: '5rem',
  godlike: '6rem',
};

const borderWidths = {
  thin: '1px',
  regular: '2px',
  thick: '3px',
};

const tableBorderStyle = `1px solid ${rgba(colours.secondaryDarkGray1, 0.25)}`;

const buttonShape = {
  small: '0.5rem 1rem',
  regular: '0.75rem 2rem',
  big: '1rem 2.5rem',
};

const minSizes = {
  [screenTypes.MOBILE]: 0,
  [screenTypes.TABLET]: 768,
  [screenTypes.LAPTOP]: 1024,
  [screenTypes.DESKTOP]: 1440,
};

const zLevels = {
  behind: -1,
  normal: 0,
  overlap: 1,
  header: 5,
  modal: 10,
};

const media = Object.keys(minSizes).reduce((acc, label) => {
  acc[label] = (...args) => css`
    @media (min-width: ${minSizes[label] / 16}em) {
      ${css(...args)}
    }
  `;
  return acc;
}, {});

const collapseMarginMixin = ({ horizontal } = {}) => (horizontal
  ? css`
    &:first-child {
      margin-left: 0;
    }

    &:last-child {
      margin-right: 0;
    }
  ` : css`
    &:first-child {
      margin-top: 0;
    }

    &:last-child {
      margin-bottom: 0;
    }
  `
);

const lineMixin = (textAlign, lineColor) => css`
  position: relative;
  padding-bottom: 0.75rem;

  &::after {
    content: '';
    width: 3rem;
    height: 0.1875rem;
    background-color: ${colours[lineColor]};
    position: absolute;
    bottom: 0;
    ${(textAlign === 'left' || textAlign === 'inherit') ? 'left: 0;' : ''}
    ${(textAlign === 'center' || textAlign === 'justify') ? 'left: calc(50% - 1.5rem);' : ''}
    ${textAlign === 'right' ? 'right: 0; left: auto;' : ''}
  }
`;

const overlappingMixin = css`
  ${({ overlapSpace }) => overlapSpace && `
    & > * {
      z-index: ${zLevels.overlap};
    }
    margin-bottom: -${padding[overlapSpace]};
  `}

  ${media.laptop`
    ${({ overlapSpace }) => overlapSpace && `
      & > * {
        z-index: ${zLevels.normal};
      }
      margin-bottom: 0;
    `}
  `}
`;

const overlappedMixin = css`
  ${({ overlapSpace, overlapColour }) => overlapSpace && `
    flex-direction: column;
    &:after {
      content: "";
      width: 100%;
      background-color: ${overlapColour};
      height: ${padding[overlapSpace]};
    }
  `}
  ${media.laptop`
    ${({ overlapSpace }) => overlapSpace && `
      &:after {
        height: 0;
      }
    `}
  `}
`;

const focusVisibleMixin = css`
  &:focus:not(:focus-visible) {
    outline: 0;
  }
`;

const textShadow = '0px 0px 8px rgba(0, 0, 0, 0.45)';
const shadows = '0px 3px 20px rgba(0, 0, 0, 0.1)';
const cardShadow = '0px 3px 20px 0px rgba(0, 0, 0, 0.1)';
const carouselCardShadow = '0px 3px 16px 0px rgba(0, 0, 0, 0.25)';
const inputShadow = `0px 3px 10px ${rgba(colours.secondaryDarkGray1, 0.2)}`;

export default {
  colours,
  fontFamily,
  fontWeight,
  fontSize,
  headlineFontSizes,
  margin,
  padding,
  borderWidths,
  tableBorderStyle,
  media,
  minSizes,
  zLevels,
  buttonShape,
  collapseMarginMixin,
  lineMixin,
  overlappingMixin,
  overlappedMixin,
  focusVisibleMixin,
  textShadow,
  shadows,
  cardShadow,
  inputShadow,
  carouselCardShadow,
};
