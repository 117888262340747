import { types } from './actions';
import bankLoginStatuses from '../../constants/bankLoginStatuses';

const initialState = {
  loginSession: {
    sessionId: '',
    provider: '',
    status: '',
    token: '', // TODO: Is this usefull ?
    error: false,
  },
  accounts: undefined,
  bankAccountId: { value: undefined, valid: null },
  startSessionLoading: false,
  retrieveDataLoading: false,
  termsApproved: {
    value: undefined,
    valid: null,
  },
  canBeProfiled: {
    value: undefined,
    valid: null,
  },
  bankProvider: {
    value: '',
    valid: null,
  },
};

export default (state = initialState, action) => {
  switch (action.type) {
    case types.BANK_RESET:
    case types.START_BANK_LOGIN_SESSION_FAILURE:
      return { ...initialState };
    case types.START_BANK_LOGIN_SESSION:
      return {
        ...state,
        loginSession: {
          ...state.loginSession,
          provider: action.provider,
          status: bankLoginStatuses.STARTED,
          parameters: action.parameters,
        },
        startSessionLoading: true,
      };
    case types.START_BANK_LOGIN_SESSION_SUCCESS:
      return {
        ...state,
        loginSession: {
          ...state.loginSession,
          sessionId: action.sessionId,
        },
        startSessionLoading: false,
      };
    case types.UPDATE_BANK_LOGIN_SESSION:
      return {
        ...state,
        loginSession: {
          ...state.loginSession,
          status: action.status,
          message: action.message,
          token: action.token,
        },
      };
    case types.RETRIEVE_BANK_DATA:
      return {
        ...state,
        loginSession: {
          ...state.loginSession,
          error: false,
        },
        retrieveDataLoading: true,
      };
    case types.RETRIEVE_BANK_DATA_SUCCESS:
      return {
        ...state,
        accounts: action.accounts,
        retrieveDataLoading: false,
      };
    case types.RETRIEVE_BANK_DATA_FAILURE:
      return {
        ...state,
        accounts: initialState.accounts,
        loginSession: {
          ...state.loginSession,
          error: true,
        },
        retrieveDataLoading: false,
      };
    case types.UPDATE_FIELD:
      return {
        ...state,
        [action.field]: {
          ...state[action.field],
          value: action.value,
        },
      };
    case types.VALIDATE_FIELD:
      return {
        ...state,
        [action.field]: {
          ...state[action.field],
          valid: action.valid,
        },
      };
    default:
      return state;
  }
};

export const loginSessionSelector = (state) => state.bank.loginSession;
