import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';

import style from '../../constants/style';

import Icon from './Icon';
import Paragraph, { StyledParagraph } from './Paragraph';

import Tick from '../../assets/check-white.svg';

const StyledSignupNavItem = styled('div')`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  margin: 0 0.5rem;
  width: calc(${(props) => 100 / props.length}% - 1rem);
  color: ${({ dark }) => (dark
    ? style.colours.primaryDark
    : style.colours.secondaryDarkGray4
  )};

  ${StyledParagraph} {
    font-size: ${({ active }) => (active ? style.fontSize.small : style.fontSize.smaller)};
    white-space: nowrap;
  }
`;

const StyledNavCircle = styled('div')`
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: ${style.fontSize.regular};
  ${({
    active, done,
  }) => `
    color: ${active ? style.colours.primaryDark : style.colours.secondaryDarkGray4};
    background-color: ${done ? style.colours.primaryDark : style.colours.primaryLight};
    border: 2px solid ${done || active ? style.colours.primaryDark : style.colours.secondaryDarkGray4};
  `}
  border-radius: 2rem;
  width: 2rem;
  height: 2rem;
  font-weight: ${style.fontWeight.regular};
  box-shadow: 0 0 0 4px ${style.colours.secondaryLightGray1};
`;

const StepperItem = ({
  active,
  done,
  step,
  index,
  length,
}) => (
  <StyledSignupNavItem
    dark={active || done}
    active={active}
    length={length}
  >
    <StyledNavCircle
      active={active}
      done={done}
    >
      {done
        ? (<Icon icon={Tick} iconHeight="40%" />)
        : index}
    </StyledNavCircle>
    <Paragraph
      margin="small"
      fontWeight="semiBold"
    >
      {step.title}
    </Paragraph>
  </StyledSignupNavItem>
);

StepperItem.propTypes = {
  active: PropTypes.bool,
  done: PropTypes.bool,
  step: PropTypes.shape({
    title: PropTypes.string.isRequired,
  }).isRequired,
  index: PropTypes.node.isRequired,
  length: PropTypes.number.isRequired,
};

StepperItem.defaultProps = {
  active: false,
  done: false,
};

export default StepperItem;
