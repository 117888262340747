import React, { useCallback, useEffect } from 'react';

import { useDispatch, useSelector } from 'react-redux';
import { push } from 'redux-first-history';

import { updateBankAccountId } from '../redux/ui/actions';

import paymentSteps from '../constants/paymentSteps';
import routes from '../constants/routes';

import AccountSelect from './modules/AccountSelect';
import Button from './elements/Button';
import BackButton from './elements/BackButton';
import FlexBox from './containers/FlexBox';
import Loader from './elements/Loader';
import Page from './containers/Page';
import PartnerLogo from './PartnerLogo';
import Stepper from './elements/Stepper';

import getUrl from '../utils/getUrl';

import useFetchApplication from '../utils/useFetchApplication';

const BankAccountSelection = () => {
  const dispatch = useDispatch();

  const bankAccountId = useSelector((state) => state.bank.bankAccountId);
  const areAccountsFetched = useSelector((state) => !!state.bank.accounts);
  const applicationId = useSelector((state) => state.ui.application.id);
  const loading = useSelector((state) => state.ui.application.loading);

  const onClickSignApplication = useCallback(() => {
    if (!bankAccountId.valid) return;
    dispatch(updateBankAccountId());
  }, [dispatch, bankAccountId.valid]);

  useFetchApplication();

  useEffect(() => {
    if (!applicationId) return;
    if (!areAccountsFetched) dispatch(push(getUrl(routes.LANDING_PAGE, { applicationId })));
  }, [dispatch, applicationId, areAccountsFetched]);

  return (
    <Page>
      <Stepper step={paymentSteps.BANK_ACCOUNT} />
      <PartnerLogo />
      {loading ? <Loader /> : (
        <>
          <AccountSelect />
          {bankAccountId && bankAccountId.valid && (
            <FlexBox marginTop="medium">
              <Button onClick={onClickSignApplication}>
                Signera autogiromedgivande
              </Button>
            </FlexBox>
          )}
        </>
      )}
      <BackButton previous={getUrl(routes.BANK_SELECT, { applicationId })} />
    </Page>
  );
};

export default BankAccountSelection;
