import {
  createStore,
  combineReducers,
  applyMiddleware,
  compose,
} from 'redux';
import { persistStore, persistReducer } from 'redux-persist';
import storageSession from 'redux-persist/lib/storage/session';
import createSagaMiddleware from 'redux-saga';
import { createBrowserHistory } from 'history';
import { createReduxHistoryContext } from 'redux-first-history';

import { rootSaga, rootReducer } from '../redux';

const { createReduxHistory, routerMiddleware, routerReducer } = createReduxHistoryContext({
  history: createBrowserHistory(),
});

const VERSION = 1;

const ROUTER_STATE_KEY = 'router';

const persistConfig = {
  key: 'root',
  storage: storageSession,
  version: VERSION,
  blacklist: [ROUTER_STATE_KEY],
  migrate: (state) => {
    if (!state || state._persist.version !== VERSION) {
      return Promise.resolve({});
    }
    return Promise.resolve(state);
  },
};

const combineReducer = combineReducers({
  [ROUTER_STATE_KEY]: routerReducer,
  ...rootReducer,
});

const persistedReducer = persistReducer(persistConfig, combineReducer);

const sagaMiddleware = createSagaMiddleware();
const middlewares = (global.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose)(
  applyMiddleware(sagaMiddleware),
  applyMiddleware(routerMiddleware),
);
const store = createStore(
  persistedReducer,
  middlewares,
);
sagaMiddleware.run(rootSaga);

export const persistor = persistStore(store);

export const history = createReduxHistory(store);

export default store;
