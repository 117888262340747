import React from 'react';

import ModalBase from './common/ModalBase';
import ModalContent from './common/ModalContent';
import Headline from '../../elements/Headline';
import Paragraph from '../../elements/Paragraph';

const InfoModal = () => (
  <ModalBase>
    <ModalContent>
      <Headline as="h3" fontSize="small" textAlign="center">Datainsamling gällande autogirobetalning</Headline>
      <Paragraph margin="none">För att kunna ge dig bästa möjliga villkor samlar vi in finansiell data. Detta görs via en kontoaggregator såsom Tink eller Open Payments. Vi samlar in nedan datapunkter. I de fall du har bekräftat att Moank löpande kan få tillgång till denna data så kommer det att inhämtas i 90 dagar.</Paragraph>
      <Paragraph marginBottom="none" fontWeight="bold">Bankkontouppgifter</Paragraph>
      <Paragraph margin="none">Detta innefattar kontonummer, saldon, kontotyper, lån, räntor och annan information knuten till dina konton. Detta inhämtas för att kunna genomföra månadsdebitering via autogiro.</Paragraph>

    </ModalContent>
  </ModalBase>
);

export default InfoModal;
