const production = require('./production');
const staging = require('./staging');
const local = require('./local');

const config = {};

if (process.env.REACT_APP_TARGET_ENV === 'production') {
  Object.assign(config, production);
} else if (process.env.REACT_APP_TARGET_ENV === 'staging') {
  Object.assign(config, staging);
} else {
  Object.assign(config, local);
}

export default config;
