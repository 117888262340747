import styled from 'styled-components';

import { StyledFlexBox } from '../containers/FlexBox';

import style from '../../constants/style';
import check from '../../assets/check-white.svg';

const SIZE = '30px';
const INPUT_WIDTH = '20px';
const INPUT_HEIGHT = '15px';

export const StyledCheckbox = styled(StyledFlexBox)`
  margin-left: ${style.margin.regular};

  & > input[type="checkbox"] {
    appearance: none;
    outline: none;

    border: 0.0625rem solid ${style.colours.secondaryDarkGray1};
    border-radius: 0.1rem;
    background-color: ${style.colours.secondaryLightGray1};
    height: ${SIZE};
    width: ${SIZE};
    cursor: pointer;
    padding: 0;
    margin: 0;

    &:disabled {
      background-color: ${style.colours.secondaryLightGray5};
      &:checked {
        background-color: ${style.colours.secondaryDarkGray4};
      }
      cursor: not-allowed;
    }

    &:checked {
      background-color: ${() => style.colours.primaryDark};
      &::after {
        content:"";
        background-image: url(${check});
        background-size: ${INPUT_WIDTH} ${INPUT_HEIGHT};
        background-repeat: no-repeat;
        display: inline-block;
        width: ${INPUT_WIDTH};
        height: ${INPUT_HEIGHT};
        position: relative;
        top: 7px;
        left: 4px;
      }
    }

    &::-ms-check{
      display: none; /* Fix on IE11 to hide the default checkbox style */
    }
  }
`;

const Checkbox = StyledCheckbox;

export default Checkbox;
