import responseHandler from '../utils/responseHandler';
import requestBuilder from '../utils/requestBuilder';
import api from '../constants/api';
import requestsMethods from '../constants/requestsMethods';

const retrievePartnerDetails = responseHandler(
  async (partnerId) => requestBuilder({
    method: requestsMethods.GET,
    url: api.PARTNER_RETRIEVE_DETAILS(partnerId),
  }),
);

export default retrievePartnerDetails;
