import React, { useCallback, useEffect, useRef } from 'react';
import { useDispatch } from 'react-redux';
import PropTypes from 'prop-types';
import styled from 'styled-components';

import style from '../../../../constants/style';

import { hideModal } from '../../../../redux/ui/actions';

import ModalOptionsContext from './context';

const StyledModalWrapper = styled.div`
  z-index: ${style.zLevels.modal};
  position: fixed;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  max-width: 100vw;

  display: block;
  background-color: rgba(0,0,0,.4);
`;

const ModalWrapper = ({ children, modalOptions }) => {
  const wrapperRef = useRef();
  const dispatch = useDispatch();

  const { preventClosing } = modalOptions;

  useEffect(() => {
    // Prevent background from scrolling
    global.document.body.style.overflow = 'hidden';
    global.document.body.style.height = '100%';

    return () => {
      // Allow background to scroll when closing modal
      global.document.body.style.cssText = '';
    };
  }, []);

  const handleWrapperClick = useCallback((e) => {
    if (e.target === wrapperRef.current && !preventClosing) dispatch(hideModal());
  }, [dispatch, preventClosing]);

  const handleEscape = useCallback((e) => {
    if (e.keyCode === 27 && !preventClosing) dispatch(hideModal());
  }, [dispatch, preventClosing]);

  return (
    <StyledModalWrapper
      ref={wrapperRef}
      onClick={handleWrapperClick}
      onKeyDown={handleEscape}
    >
      <ModalOptionsContext.Provider value={modalOptions}>
        {children}
      </ModalOptionsContext.Provider>
    </StyledModalWrapper>
  );
};

ModalWrapper.propTypes = {
  children: PropTypes.node.isRequired,
  modalOptions: PropTypes.shape({
    preventClosing: PropTypes.bool,
  }),
};

ModalWrapper.defaultProps = {
  modalOptions: {},
};

export default ModalWrapper;
