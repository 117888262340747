import responseHandler from '../utils/responseHandler';
import requestBuilder from '../utils/requestBuilder';
import requestsMethods from '../constants/requestsMethods';
import api from '../constants/api';

const settingsFetch = responseHandler(async () => requestBuilder({
  method: requestsMethods.GET,
  url: api.FETCH_PUBLIC_SETTINGS,
}));

export default settingsFetch;
