import React from 'react';
import ReactDOM from 'react-dom/client';
import * as Sentry from '@sentry/react';

import { Provider } from 'react-redux';

import { Routes, Route } from 'react-router-dom';
import { HistoryRouter as Router } from 'redux-first-history/rr6';

import { PersistGate } from 'redux-persist/integration/react';

import store, { persistor, history } from './utils/store';

import BankLoginCallback from './components/BankLoginCallback';
import BankAccountSelection from './components/BankAccountSelection';
import LandingPage from './components/LandingPage';
import ThankYouPage from './components/ThankYouPage';

import routes from './constants/routes';

import configs from './configs';
import getUrl from './utils/getUrl';
import BankSelectPage from './components/BankSelectPage';

const root = ReactDOM.createRoot(document.getElementById('root'));

Sentry.init({
  dsn: configs.sentry.dsn,
  environment: configs.sentry.environment,
  integrations: [],
});

root.render(
  <Provider store={store}>
    <PersistGate loading={null} persistor={persistor}>
      <Router history={history}>
        <Routes>
          <Route path="*" element={<>There is nothing in there!</>} />
          <Route path={getUrl(routes.LANDING_PAGE)} element={<LandingPage />} />
          <Route path={getUrl(routes.BANK_SELECT)} element={<BankSelectPage />} />
          <Route path={getUrl(routes.BANK_LOGIN_CALLBACK)} element={<BankLoginCallback />} />
          <Route path={getUrl(routes.BANK_ACCOUNT_SELECTION)} element={<BankAccountSelection />} />
          <Route path={getUrl(routes.THANK_YOU_PAGE)} element={<ThankYouPage />} />
        </Routes>
      </Router>
    </PersistGate>
  </Provider>,
);
