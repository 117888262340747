import React from 'react';
import PropTypes from 'prop-types';
import styled, { keyframes } from 'styled-components';

import style from '../../constants/style';

import Paragraph from './Paragraph';
import FlexBox from '../containers/FlexBox';

const spin = keyframes`
  0% {transform: rotate(0deg); }
  100% { transform: rotate(360deg); }
`;

const StyledSpinner = styled.div`
  border: 0.5rem solid ${style.colours.secondaryLightGray5};
  border-top: 0.5rem solid ${({ theme }) => theme.primaryColour};
  border-radius: 50%;
  width: 4rem;
  height: 4rem;
  animation: ${spin} 0.75s linear infinite;
`;

const Loader = ({ spinner, message }) => (
  <FlexBox alignItems="center" flexDirection="column">
    {spinner && (
      <img
        src={spinner}
        alt=""
        style={{ maxHeight: '200px' }}
      />
    )}
    {!spinner && (<StyledSpinner />)}
    {message && <Paragraph textAlign="center">{message}</Paragraph>}
  </FlexBox>
);

Loader.propTypes = {
  spinner: PropTypes.string,
  message: PropTypes.string,
};

Loader.defaultProps = {
  spinner: undefined,
  message: '',
};

export default Loader;
