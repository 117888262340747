import paymentSteps from './paymentSteps';

const paymentFlow = [
  {
    step: paymentSteps.INFORMATION,
    title: 'Information',
  },
  {
    step: paymentSteps.BANK_SELECT,
    title: 'Bank',
  },
  {
    step: paymentSteps.BANK_ACCOUNT,
    title: 'Bankkonto',
  },
];

export default paymentFlow;
