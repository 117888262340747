import React, { useRef, useCallback, useContext } from 'react';
import { useDispatch } from 'react-redux';
import PropTypes from 'prop-types';

import { hideModal } from '../../../../redux/ui/actions';

import Modal, { ModalCloseButton } from './Modal';

import useModalFocus from '../../../../utils/useModalFocus';

import ModalOptionsContext from './context';

const ModalBase = ({
  children,
  large,
}) => {
  const closeButtonRef = useRef();
  const dispatch = useDispatch();
  const onClose = useCallback((e) => {
    e.preventDefault();
    dispatch(hideModal());
  }, [dispatch]);

  useModalFocus(closeButtonRef);

  const options = useContext(ModalOptionsContext);

  return (
    <Modal large={large} closeButtonHidden={options.preventClosing}>
      {!options.preventClosing && (
        <ModalCloseButton ref={closeButtonRef} onClick={onClose} />
      )}
      {children}
    </Modal>
  );
};

ModalBase.propTypes = {
  children: PropTypes.node.isRequired,
  large: PropTypes.bool,
};

ModalBase.defaultProps = {
  large: false,
};

export default ModalBase;
