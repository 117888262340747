import { useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { useParams } from 'react-router-dom';

import { fetchApplication } from '../redux/ui/actions';

const useFetchApplication = () => {
  const urlParams = useParams();
  const dispatch = useDispatch();

  useEffect(() => {
    if (!urlParams.applicationId) return;
    dispatch(fetchApplication(urlParams.applicationId));
  }, [dispatch, urlParams.applicationId]);
};

export default useFetchApplication;
